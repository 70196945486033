import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable } from 'rxjs';

export class DefaultDataSource<T> implements DataSource<T> {
  private data$ = new BehaviorSubject<T[]>([]);
  private isLoading$ = new BehaviorSubject<boolean>(false);

  public connect(): Observable<T[]> {
    return this.data$.asObservable();
  }

  public disconnect(): void {
    this.data$.complete();
    this.isLoading$.complete();
  }

  public get data(): T[] {
    return this.data$.value;
  }

  public set data(data: T[]) {
    this.data$.next(data);
  }
}
